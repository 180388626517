import b1 from "./assets/img/b1.png";
import "./App.css";
import main from "./assets/js/main";
import Testimonial from "./components/Testimonials/testimonial";
import questImage1 from "./assets/img/projects/questimage1.png";
import propSystemImage1 from "./assets/img/projects/propSystemImage1.png";
import eggSystemImage1 from "./assets/img/projects/egghatchimage.png";

function Home() {
  return (
    <>
      <header class="l-header" id="header" style={{ overflow: "auto" }}>
        <nav class="nav bd-grid">
          <div>
            <a href="#home" class="nav__logo">
              Bungfro
            </a>
          </div>

          <div class="nav__menu" id="nav-menu">
            <ul class="nav__list">
              <li class="nav__item">
                <a href="#home" class="nav__link">
                  Home
                </a>
              </li>
              <li class="nav__item">
                <a href="#about" class="nav__link">
                  About
                </a>
              </li>
              <li class="nav__item">
                <a href="#skills" class="nav__link">
                  Skills
                </a>
              </li>
              <li class="nav__item">
                <a href="#testimonials" class="nav__link">
                  Testimonials
                </a>
              </li>
              <li class="nav__item">
                <a href="#work" class="nav__link">
                  Work
                </a>
              </li>
              <li class="nav__item">
                <a href="#contact" class="nav__link">
                  Contact
                </a>
              </li>
            </ul>
          </div>

          <div class="nav__toggle" id="nav-toggle">
            <i class="fa fa-bars"></i>
          </div>
        </nav>
      </header>
      <main class="l-main">
        <section class="home bd-grid" id="home">
          <div class="home__data">
            <h1 class="home__title">
              Hi,
              <br />
              I'm <span class="home__title-color">Bungfro</span> a
              <br /> Full-Stack
              <br />
              Developer
            </h1>

            <a href="#contact" class="button">
              Contact
            </a>
          </div>

          <div class="home__social" style={{ marginLeft: "100px" }}>
            <a
              href="https://x.com/Bungfro_"
              class="home__social-icon"
              target="_blank"
              rel="noreferrer"
            >
              <i class="fa-brands fa-x"></i>
            </a>
            <a
              href="https://discord.com/users/696057348678090825"
              class="home__social-icon"
              target="_blank"
              rel="noreferrer"
            >
              <i class="fa-brands fa-discord"></i>
            </a>
            <a
              href="https://github.com/devbungfro"
              class="home__social-icon"
              target="_blank"
              rel="noreferrer"
            >
              <i class="fa-brands fa-github"></i>
            </a>
          </div>

          <div class="home__img">
            <svg
              className="home__blob"
              viewBox="0 0 479 467"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
            >
              <mask id="mask0" mask-type="alpha">
                <path d="M9.19024 145.964C34.0253 76.5814 114.865 54.7299 184.111 29.4823C245.804 6.98884 311.86 -14.9503 370.735 14.143C431.207 44.026 467.948 107.508 477.191 174.311C485.897 237.229 454.931 294.377 416.506 344.954C373.74 401.245 326.068 462.801 255.442 466.189C179.416 469.835 111.552 422.137 65.1576 361.805C17.4835 299.81 -17.1617 219.583 9.19024 145.964Z" />
              </mask>
              <g mask="url(#mask0)">
                <path d="M9.19024 145.964C34.0253 76.5814 114.865 54.7299 184.111 29.4823C245.804 6.98884 311.86 -14.9503 370.735 14.143C431.207 44.026 467.948 107.508 477.191 174.311C485.897 237.229 454.931 294.377 416.506 344.954C373.74 401.245 326.068 462.801 255.442 466.189C179.416 469.835 111.552 422.137 65.1576 361.805C17.4835 299.81 -17.1617 219.583 9.19024 145.964Z" />
                <image class="home__blob-img" x="80" y="60" href={b1} />
              </g>
            </svg>
          </div>
        </section>

        <section class="about section " id="about">
          <h2 class="section-title">About</h2>

          <div class="about__container bd-grid">
            <div class="about__img">
              <img src={b1} alt="" />
            </div>

            <div>
              <h2 class="about__subtitle">I'm Bungfro</h2>
              <p class="about__text">
                Hello, my name is Bungfro, and I am a skilled programmer with
                expertise in Javascript, Lua, and Luau. I have been programming
                for some time now and have developed a deep understanding of
                these languages, enabling me to create powerful and dynamic
                applications. <br />
                <br />
                My experience in Javascript has equipped me with the knowledge
                to build robust, interactive web applications. With Lua and
                Luau, I have developed expertise in creating games and other
                software projects. Over the years, I have worked on various
                projects, from creating web applications to developing games for
                various platforms. <br />
                <br /> As a developer, I am passionate about solving complex
                problems through programming, and I am always looking for new
                challenges to take on. I am constantly learning and exploring
                new technologies to enhance my skills and stay up to date with
                the latest trends in programming.
              </p>
              <div class="about__info">
                <div>
                  <span class="about__info-title">09+</span>
                  <span class="about__info-name">
                    Years <br /> experience
                  </span>
                </div>
                <div>
                  <span class="about__info-title">95%</span>
                  <span class="about__info-name">
                    Positive <br /> feedback
                  </span>
                </div>
                <div>
                  <span class="about__info-title">80%</span>
                  <span class="about__info-name">
                    Projects
                    <br />
                    completed
                  </span>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="skills section" id="skills">
          <h2 class="section-title">Skills</h2>

          <div class="container">
            <div class="row" id="skillsContainer"></div>
          </div>
        </section>

        <section class="testimonials section" id="testimonials">
          <h2 class="section-title">Testimonials</h2>
          <Testimonial />
        </section>

        <section className="work section" id="work">
          <h2 class="section-title">Work</h2>

          <div className="container grid grid-cols-[repeat(auto-fit,_31.33333333%)] justify-center items-center">
            <div class="max-w-sm rounded overflow-hidden shadow-lg">
              <img
                class="w-full"
                src={questImage1}
                alt="Sunset in the mountains"
              />
              <div class="px-6 py-4">
                <div class="font-bold text-xl mb-2">Quest System</div>
                <p class="text-gray-700 text-base">
                  Dive into my Roblox Quest System project, where players can
                  enjoy exciting adventures and complete missions. It's a simple
                  and engaging game feature that highlights my skills in
                  creating interactive experiences within Roblox.
                </p>
              </div>
              <div className="flex justify-center mt-5">
                <a href="/projects/1" className="button button--flex">
                  View
                </a>
              </div>
              <div class="px-6 pt-4 pb-2">
                <span class="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">
                  #roblox
                </span>
                <span class="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">
                  #robloxdev
                </span>
              </div>
            </div>
            <div class="max-w-sm rounded overflow-hidden shadow-lg">
              <img
                class="w-full"
                src={propSystemImage1}
                alt="Sunset in the mountains"
              />
              <div class="px-6 py-4">
                <div class="font-bold text-xl mb-2">Prop System</div>
                <p class="text-gray-700 text-base">
                  This Roblox Prop System allows you to morph into anything! It
                  even changes the gravity and fov based on the size of the
                  prop, when you change props, your camera nicely tweens back to
                  it's original position.
                </p>
              </div>
              <div className="flex justify-center mt-5">
                <a href="/projects/2" className="button button--flex">
                  View
                </a>
              </div>
              <div class="px-6 pt-4 pb-2">
                <span class="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">
                  #roblox
                </span>
                <span class="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">
                  #robloxdev
                </span>
              </div>
            </div>
            <div class="max-w-sm rounded overflow-hidden shadow-lg">
              <img
                class="w-full"
                src={eggSystemImage1}
                alt="Sunset in the mountains"
              />
              <div class="px-6 py-4">
                <div class="font-bold text-xl mb-2">Pet / Egg System</div>
                <p class="text-gray-700 text-base">
                  Discover my newest pet system, mirroring the aesthetics of Pet
                  Simulator X's interface. Upon egg interaction, the pet UI
                  emerges, enabling single or triple hatching. Fully
                  customizable, tweak everything from appearance to abilities
                  and behaviors.
                </p>
              </div>
              <div className="flex justify-center mt-5">
                <a href="/projects/3" className="button button--flex">
                  View
                </a>
              </div>
              <div class="px-6 pt-4 pb-2">
                <span class="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">
                  #roblox
                </span>
                <span class="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">
                  #robloxdev
                </span>
              </div>
            </div>
          </div>
          <div className="flex justify-center mt-5">
            <a href="/work" className="button button--flex">
              More
              <i
                className="fa fa-arrow-right button__icon"
                style={{ marginLeft: "5px" }}
              ></i>
            </a>
          </div>
        </section>

        <section class="contact section" id="contact">
          <h2 class="section-title">Contact Me</h2>

          <div class="contact__container container grid">
            <div>
              <div class="contact__information">
                <i class="fa-brands fa-x contact__icon"></i>

                <div>
                  <h3 class="contact__title">X</h3>
                  <span class="contatc__subtitle">@Bungfro_</span>
                </div>
              </div>

              <div class="contact__information">
                <i class="fa fa-envelope contact__icon"></i>

                <div>
                  <h3 class="contact__title">E-mail</h3>
                  <span class="contatc__subtitle">bungfro@bungfro.com</span>
                </div>
              </div>

              <div class="contact__information">
                <i class="fa-brands fa-discord contact__icon"></i>

                <div>
                  <h3 class="contact__title">Discord</h3>
                  <span class="contatc__subtitle">bungfro</span>
                </div>
              </div>
            </div>

            <form action="" class="contact__form grid">
              <div class="contact__inputs grid">
                <div class="contact__content">
                  <label for="" class="contact__label">
                    Name
                  </label>
                  <input type="text" class="contact__input" />
                </div>

                <div class="contact__content">
                  <label for="" class="contact__label">
                    E-mail
                  </label>
                  <input type="email" class="contact__input" />
                </div>
              </div>

              <div class="contact__content">
                <label for="" class="contact__label">
                  Subject
                </label>
                <input type="text" class="contact__input" />
              </div>

              <div class="contact__content">
                <label for="" class="contact__label">
                  Description
                </label>
                <textarea
                  name=""
                  id=""
                  cols="0"
                  rows="7"
                  class="contact__input"
                ></textarea>
              </div>

              <div>
                <a href="#contact" class="button button--flex">
                  Send message (DISABLED)
                  <i class="uil uil-message button__icon"></i>
                </a>
              </div>
            </form>
          </div>
        </section>
      </main>
    </>
  );
}

export default Home;
