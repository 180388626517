import gumshoeMin from "gumshoejs";
import skillsJson from "./skills.json";

if (window.location.pathname === "/") {
  async function fetchData(type = "skills") {
    const data = type === "skills" ? skillsJson : skillsJson;
    return data;
  }

  function showSkills(skills) {
    let skillsContainer = document.getElementById("skillsContainer");
    if (skillsContainer) {
      let skillHTML = "";
      skills.forEach((skill) => {
        skillHTML += `
        <div class="bar">
              <div class="info">
                <img src=${skill.icon} alt="skill" />
                <span>${skill.name}</span>
              </div>
            </div>`;
      });
      skillsContainer.innerHTML = skillHTML;
    }
  }

  window.onload = function () {
    fetchData().then((data) => {
      showSkills(data);
    });

    const sections = document.querySelectorAll("section");
    const navLi = document.querySelectorAll(".nav__menu a");
    console.log(navLi);
    window.onscroll = () => {
      var current = "";

      sections.forEach((section) => {
        const sectionTop = section.offsetTop;
        // eslint-disable-next-line no-restricted-globals
        if (pageYOffset >= sectionTop) {
          current = section.getAttribute("id");
        }
      });

      navLi.forEach((li) => {
        li.classList.remove("active-link");
        console.log(li.hash, current);
        if (li.hash.includes(current)) {
          li.classList.add("active-link");
        }
      });
    };
  };

  document.addEventListener("visibilitychange", function () {
    if (document.visibilityState === "visible") {
      document.title = "Portfolio | Bungfro";
    } else {
      document.title = "Don't leave! Come back.";
    }
  });

  function scrollHeader() {
    const nav = document.getElementById("header");
    if (this.scrollY >= 80) nav.classList.add("scroll-header");
    else nav.classList.remove("scroll-header");
  }
  window.addEventListener("scroll", scrollHeader);
}
