import React, { useEffect, useState } from "react";
import { FiTag, FiArrowLeft } from "react-icons/fi";
import { motion } from "framer-motion";
import eggSystemImage1 from "../assets/img/projects/egghatchimage.png";
import { useParams, useNavigate } from "react-router-dom";
import { data } from "../data/projects";

function Project() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [project, setProject] = useState(null);
  useEffect(() => {
    setProject(data[id]);
  }, [id]);
  if (project) {
    return (
      <>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, delay: 1 }}
          transition={{
            ease: "easeInOut",
            duration: 0.6,
            delay: 0.15,
          }}
          className="container mx-auto mt-5 sm:mt-10"
        >
          {/* Start Header */}
          <div>
            <p className="font-general-medium text-left text-3xl sm:text-4xl font-bold text-primary-dark dark:text-primary-light mt-14 sm:mt-20 mb-7 flex flex-row items-center gap-3">
              <FiArrowLeft className="text-lg text-ternary-dark dark:text-ternary-light cursor-pointer" onClick={() => navigate(-1)} />
              {project.ProjectHeader.title}
            </p>
            <div className="flex">
              <div className="flex items-center">
                <FiTag className="text-lg text-ternary-dark dark:text-ternary-light" />
                <span className="font-general-regular ml-2 leading-none text-primary-dark dark:text-primary-light">
                  {project.ProjectHeader.tags}
                </span>
              </div>
            </div>
          </div>
          {/* End Header */}
          {/* Start Gallery */}
          <div className="grid grid-cols-[repeat(auto-fit,_100%)] sm:gap-10 mt-12 justify-center items-center">
            <div className="mb-10 sm:mb-0">
              <img
                src={project.ProjectImages[0].img}
                className="rounded-xl cursor-pointer shadow-lg sm:shadow-none"
                alt={project.ProjectImages[0].title}
              />
            </div>
          </div>
          {/* End Gallery */}
          {/* Start Project Info */}
          <div className="block sm:flex gap-0 sm:gap-10 mt-14">
            <div className="w-full sm:w-1/3 text-left">
              <div className="mb-7">
                <p className="font-general-regular text-2xl font-semibold text-secondary-dark dark:text-secondary-light mb-2">
                  {project.ProjectInfo.ClientHeading}
                </p>
                <ul className="leading-loose">
                  {project.ProjectInfo.CompanyInfo.map((info) => {
                    return (
                      <li
                        className="font-general-regular text-ternary-dark dark:text-ternary-light"
                        key={info.id}
                      >
                        <span>{info.title}: </span>
                        <a
                          href="https://stoman.me"
                          className={
                            info.title === "Website" || info.title === "Phone"
                              ? "hover:underline hover:text-indigo-500 dark:hover:text-indigo-400 cursor-pointer duration-300"
                              : ""
                          }
                          aria-label="Project Website and Phone"
                        >
                          {info.details}
                        </a>
                      </li>
                    );
                  })}
                </ul>
              </div>

              <div className="mb-7">
                <p className="font-general-regular text-2xl font-semibold text-ternary-dark dark:text-ternary-light mb-2">
                  {project.ProjectInfo.ObjectivesHeading}
                </p>
                <p className="font-general-regular text-primary-dark dark:text-ternary-light">
                  {project.ProjectInfo.ObjectivesDetails}
                </p>
              </div>

              <div className="mb-7">
                <p className="font-general-regular text-2xl font-semibold text-ternary-dark dark:text-ternary-light mb-2">
                  {project.ProjectInfo.Technologies[0].title}
                </p>
                <p className="font-general-regular text-primary-dark dark:text-ternary-light">
                  {project.ProjectInfo.Technologies[0].techs.join(", ")}
                </p>
              </div>

              <div>
                <p className="font-general-regular text-2xl font-semibold text-ternary-dark dark:text-ternary-light mb-2">
                  {project.ProjectInfo.SocialSharingHeading}
                </p>
                <div className="flex items-center gap-3 mt-5">
                  {project.ProjectInfo.SocialSharing.map((social) => {
                    return (
                      <a
                        key={social.id}
                        href={social.url}
                        target="__blank"
                        aria-label="Share Project"
                        className="bg-ternary-light dark:bg-ternary-dark text-gray-400 hover:text-primary-dark dark:hover:text-primary-light p-2 rounded-lg shadow-sm duration-500"
                      >
                        <span className="text-lg lg:text-2xl">
                          {social.icon}
                        </span>
                      </a>
                    );
                  })}
                </div>
              </div>
            </div>

            {/*  Single project right section */}
            <div className="w-full sm:w-2/3 text-left mt-10 sm:mt-0">
              <p className="font-general-regular text-primary-dark dark:text-primary-light text-2xl font-bold mb-7">
                {project.ProjectInfo.ProjectDetailsHeading}
              </p>
              {project.ProjectInfo.ProjectDetails.map((details) => {
                return (
                  <p
                    key={details.id}
                    className="font-general-regular mb-5 text-lg text-ternary-dark dark:text-ternary-light"
                  >
                    {details.details}
                  </p>
                );
              })}
            </div>
          </div>
        </motion.div>
      </>
    );
  }
  return <p>Not Found</p>;
}

export default Project;
