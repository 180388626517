import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./Home";
import Project from "./projects/Project";
import Work from "./Work";
import { ProjectsProvider } from "./context/ProjectContext";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route
          path="/work"
          element={
            <ProjectsProvider>
              {" "}
              <Work />{" "}
            </ProjectsProvider>
          }
        />
        <Route path="/projects/:id" element={<Project />} />
      </Routes>
    </Router>
  );
}

export default App;
