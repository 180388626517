import React from "react";
import { ClientsInfo } from "./ClientsInfo";
import "./testimonial.css";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css/bundle";

// import required modules
import { Navigation, Pagination } from "swiper/modules";

const Testimonial = () => {
  return (
    <>
      <section className="testimonial container section" id="testimonials">
        <Swiper
          className="testimonial__container"
          loop={true}
          grabCursor={true}
          spaceBetween={24}
          pagination={true}
          breakpoints={{
            576: {
              slidesPerView: 2,
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 48,
            },
          }}
          modules={[Navigation, Pagination]}
        >
          {ClientsInfo.map(({ id, image, title, description, stars }) => {
            return (
              <SwiperSlide className="testimonial__card" key={id}>
                <img
                  src={image}
                  alt="testimonial__img"
                  className="testimonial__img"
                />
                <h3 className="testimonial__name">{title} </h3>
                <i data-star={stars}></i>
                <p className="testimonal__description">{description}</p>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </section>
    </>
  );
};

export default Testimonial;
