import React, { useContext } from "react";
import { FiSearch, FiArrowLeft } from "react-icons/fi";
import { ProjectsContext, ProjectsProvider } from "./context/ProjectContext";
import ProjectsFilter from "./components/ProjectsFilter";
import { useNavigate } from "react-router-dom";

function Work() {
  const {
    projects,
    searchProject,
    setSearchProject,
    searchProjectsByTitle,
    selectProject,
    setSelectProject,
    selectProjectsByCategory,
  } = useContext(ProjectsContext);
  const navigate = useNavigate();

  return (
    <div className="container mx-auto">
      <section className="py-5 sm:py-10 mt-5 sm:mt-10">
        <div className="text-center">
          <p className="font-general-medium text-2xl sm:text-4xl mb-1 text-ternary-dark dark:text-ternary-light flex flex-row items-center justify-center gap-10">
            <FiArrowLeft
              className="text-lg text-ternary-dark dark:text-ternary-light cursor-pointer"
              onClick={() => navigate(-1)}
            />{" "}
            Projects portfolio
          </p>
        </div>

        <div className="mt-5 sm:mt-10">
          <h3
            className="font-general-regular 
                        text-center text-secondary-dark
                        dark:text-ternary-light
                        text-md
                        sm:text-xl
                        mb-3
                        "
          >
            Search projects by title or filter by category
          </h3>
          <div
            className="
                        flex
                        justify-between
                        border-b border-primary-light
                        dark:border-secondary-dark
                        pb-3
                        gap-3
                        "
          >
            <div className="flex justify-between gap-2">
              <span
                className="
                                hidden
                                sm:block
                                bg-primary-light
                                dark:bg-ternary-dark
                                p-2.5
                                shadow-sm
                                rounded-xl
                                cursor-pointer
                                "
              >
                <FiSearch className="text-ternary-dark dark:text-ternary-light w-5 h-5"></FiSearch>
              </span>
              <input
                onChange={(e) => {
                  setSearchProject(e.target.value);
                }}
                className="font-general-medium 
                                pl-3
                                pr-1
                                sm:px-4
                                py-2
                                border 
                            border-gray-200
                                dark:border-secondary-dark
                                rounded-lg
                                text-sm
                                sm:text-md
                                bg-secondary-light
                                dark:bg-ternary-dark
                                text-primary-dark
                                dark:text-ternary-light
                                "
                id="name"
                name="name"
                type="search"
                required=""
                placeholder="Search Projects"
                aria-label="Name"
              />
            </div>

            <ProjectsFilter setSelectProject={setSelectProject} />
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 mt-6 sm:gap-10">
            {selectProject
              ? selectProjectsByCategory.map((project, index) => (
                  <div
                    key={index}
                    class="max-w-sm rounded overflow-hidden shadow-lg"
                  >
                    <img
                      class="w-full"
                      src={project.ProjectImages[0].img}
                      alt="Sunset in the mountains"
                    />
                    <div class="px-6 py-4">
                      <div class="font-bold text-xl mb-2">
                        {project.ProjectHeader.title}
                      </div>
                      <p class="text-gray-700 text-base"></p>
                    </div>
                    <div className="flex justify-center items-center mt-5 mb-5">
                      <a
                        href={`/projects/${project.id}`}
                        className="button button--flex"
                      >
                        View
                      </a>
                    </div>
                    <div class="px-6 pt-4 pb-2">
                      {project.ProjectHeader.tags.split(",").map((tag, i) => (
                        <span
                          key={i}
                          className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2"
                        >
                          #{tag.trim()}
                        </span>
                      ))}
                    </div>
                  </div>
                ))
              : searchProject
              ? searchProjectsByTitle.map((project, index) => (
                  <div
                    key={index}
                    class="max-w-sm rounded overflow-hidden shadow-lg"
                  >
                    <img
                      class="w-full"
                      src={project.ProjectImages[0].img}
                      alt="Sunset in the mountains"
                    />
                    <div class="px-6 py-4">
                      <div class="font-bold text-xl mb-2">
                        {project.ProjectHeader.title}
                      </div>
                      <p class="text-gray-700 text-base"></p>
                    </div>
                    <div className="flex justify-center items-center mt-5 mb-5">
                      <a
                        href={`/projects/${project.id}`}
                        className="button button--flex"
                      >
                        View
                      </a>
                    </div>
                    <div class="px-6 pt-4 pb-2">
                      {project.ProjectHeader.tags.split(",").map((tag, i) => (
                        <span
                          key={i}
                          className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2"
                        >
                          #{tag.trim()}
                        </span>
                      ))}
                    </div>
                  </div>
                ))
              : Object.values(projects).map((project, index) => (
                  <div
                    key={index}
                    class="max-w-sm rounded overflow-hidden shadow-lg"
                  >
                    <img
                      class="w-full"
                      src={project.ProjectImages[0].img}
                      alt="Sunset in the mountains"
                    />
                    <div class="px-6 py-4">
                      <div class="font-bold text-xl mb-2">
                        {project.ProjectHeader.title}
                      </div>
                      <p class="text-gray-700 text-base"></p>
                    </div>
                    <div className="flex justify-center items-center mt-5 mb-5">
                      <a
                        href={`/projects/${project.id}`}
                        className="button button--flex"
                      >
                        View
                      </a>
                    </div>
                    <div class="px-6 pt-4 pb-2">
                      {project.ProjectHeader.tags.split(",").map((tag, i) => (
                        <span
                          key={i}
                          className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2"
                        >
                          #{tag.trim()}
                        </span>
                      ))}
                    </div>
                  </div>
                ))}
          </div>
        </div>
      </section>
    </div>
  );
}

export default Work;
