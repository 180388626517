import Image1 from "../../assets/img/lorepfp.webp";
import Image2 from "../../assets/img/d1.png";
import Image3 from "../../assets/img/d1.png";
import Image4 from "../../assets/img/d1.png";

export const ClientsInfo = [
  {
    id: 1,
    image: Image1,
    title: "LoreOfThrones",
    stars: 5,
    description:
      '"I’ve been running genres on the ROBLOX platform since 2012… the commitment and dedication of this individual is like no other developer I’ve ever seen. I have hired more developers than I can count on two hands, Bungfro by far has taken the cake for his in depth knowledge of LUA. The biggest win to me is the dedication and commitment that I’ve seen come from Bungfro, not once or twice, but almost on a DAILY basis."',
  },
  {
    id: 2,
    image: Image2,
    title: "desertfoxgaming",
    stars: 5,
    description:
      "Bot works great and the seller worked hard on every little thing that I nitpick about. (From Fiverr)",
  },
  {
    id: 3,
    image: Image3,
    title: "nijabunny",
    stars: 5,
    description:
      "He is so amazing! He's very professional, he answers very quickly and he had finished the job overnight even considering my request was rather advanced. He was willing to answer all of my questions even after the job was done and went above and beyond to make sure I was happy with the end product. The price was amazing for the service and I'm even planning to hire him for even more work on my bot very soon! He was also very patient with me, he made sure I understood everything properly and told me I can message him again anytime with questions! <3 (From Fiverr - Ordered 3 times)",
  },
  {
    id: 4,
    image: Image4,
    title: "dougm1966",
    stars: 5,
    description:
      "AMAZING! Within freaking hours my project was exactly how I wanted it and I will DEFINITELY be working with Bungfro Again! Already talking about our v2 of the bot! So Stoked!  (From Fiverr)",
  },
];
