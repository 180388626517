import { useState, createContext } from "react";
import { data as projectData } from "../data/projects";

// Create projects context
export const ProjectsContext = createContext();

// Create the projects context provider
export const ProjectsProvider = (props) => {
  const [projects, setProjects] = useState(projectData);
  const [searchProject, setSearchProject] = useState("");
  const [selectProject, setSelectProject] = useState("");

  // Search projects by project title
  const searchProjectsByTitle = Object.values(projects).filter((item) => {
    if (searchProject === "") {
      return true;
    }
    return item.ProjectHeader.title
      .toLowerCase()
      .includes(searchProject.toLowerCase());
  });

  const selectProjectsByCategory = Object.values(projects).filter((item) => {
    let category =
      item.category.charAt(0).toUpperCase() + item.category.slice(1);
    if (category.includes(selectProject)) {
      return true;
    }
    return false;
  }, {});

  return (
    <ProjectsContext.Provider
      value={{
        projects,
        setProjects,
        searchProject,
        setSearchProject,
        searchProjectsByTitle,
        selectProject,
        setSelectProject,
        selectProjectsByCategory,
      }}
    >
      {props.children}
    </ProjectsContext.Provider>
  );
};
